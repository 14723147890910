import img01 from "../all-images/cars-img/1.png";
import img02 from "../all-images/cars-img/2.png";
import img03 from "../all-images/cars-img/3.png";
import img04 from "../all-images/cars-img/4.png";
import img05 from "../all-images/cars-img/5.png";
import img06 from "../all-images/cars-img/6.png";
import img07 from "../all-images/cars-img/7.png";
import img08 from "../all-images/cars-img/8.png";
import img09 from "../all-images/cars-img/9.png";
import img10 from "../all-images/cars-img/10.png";
import img11 from "../all-images/cars-img/11.png";
import img12 from "../all-images/cars-img/12.png";
import img13 from "../all-images/cars-img/13.png";
import img14 from "../all-images/cars-img/14.png";
import img15 from "../all-images/cars-img/15.png";
import img16 from "../all-images/cars-img/16.png";
import img17 from "../all-images/cars-img/17.png";
import img18 from "../all-images/cars-img/18.png";
import img19 from "../all-images/cars-img/19.png";
import img20 from "../all-images/cars-img/20.png";
import img21 from "../all-images/cars-img/21.png";
import img22 from "../all-images/cars-img/22.png";
import img23 from "../all-images/cars-img/23.png";
import img24 from "../all-images/cars-img/24.png";
import img25 from "../all-images/cars-img/zentrike.png";
import img26 from "../all-images/cars-img/electroar.png";
import img27 from "../all-images/cars-img/trirover.png";
import img28 from "../all-images/cars-img/ogretrike.png";

const carData = [
  {
    id: 1,
    brand: "Tesla",
    rating: 4.5,
    carName: "Tesla Model 3 Rear Drive 2023",
    imgUrl: img01,
    model: "264 HP",
    price: "31,700",
    speed: "60.00 kWh/606 km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Tesla Model 3 Rear-Wheel Drive 2023 is designed for efficiency and performance. It features advanced technology and safety features, making it a popular choice for eco-conscious drivers. With heated seats and GPS navigation, it provides a comfortable driving experience.",
  },

  {
    id: 2,
    brand: "Tesla",
    rating: 4.2,
    carName: "Tesla Model 3 2023 Long Range AWD Ver",
    imgUrl: img02,
    model: "491 HP",
    price: "36,900",
    speed: "75.0 kWh/713 km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Tesla Model 3 2023 Long Range AWD offers a remarkable balance of range and performance. It features all-wheel drive, GPS navigation, and a cozy interior with heated seats, making it ideal for both city driving and long-distance travel."
  },
  
  {
    id: 3,
    brand: "Tesla",
    rating: 4.6,
    carName: "Tesla Model Y 2023 Rear Wheel Drive version",
    imgUrl: img03,
    model: "264 HP",
    price: "35,689",
    speed: "82.1 kWh/554 km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Tesla Model Y 2023 Rear Wheel Drive combines luxury and performance with an all-electric powertrain. It features advanced technology, heated seats, and GPS navigation, providing a comfortable and efficient driving experience for eco-conscious drivers."
  },

  {
    id: 4,
    brand: "Tesla",
    rating: 4.1,
    carName: "Tesla Model Y 2023 Long Range AWD Version range",
    imgUrl: img04,
    model: "347 HP",
    price: "41,422",
    speed: "82.1 kWh/713 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Tesla Model Y 2023 Long Range AWD is an all-electric vehicle that emphasizes performance and efficiency. With features like heated seats and GPS navigation, it provides a comfortable and enjoyable driving experience, perfect for both city and long-distance travel."
  },

  {
    id: 5,
    brand: "VW",
    rating: 4.0,
    carName: "VW ID3 Active Pure",
    imgUrl: img05,
    model: "148 HP",
    price: "17,100",
    speed: "45 kWh/450 km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The VW ID3 Active Pure is an electric hatchback designed for versatility and comfort. With features like heated seats and GPS navigation, it provides an enjoyable driving experience, making it suitable for both city commutes and longer journeys."
  },

  {
    id: 6,
    brand: "VW",
    rating: 4.5,
    carName: "VW ID3 Extreme Intelligent",
    imgUrl: img06,
    model: "148 HP",
    price: "17,115",
    speed: "45 kWh/450 km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The VW ID3 Extreme Intelligent is a luxury electric hatchback that combines performance with cutting-edge technology. It features heated seats and GPS navigation for a comfortable and connected driving experience, making it perfect for families and long journeys."
  },

  {
    id: 7,
    brand: "VW",
    rating: 4.2,
    carName: "VW ID4 2024 Pure + Version",
    imgUrl: img07,
    model: "282 HP",
    price: "19,310",
    speed: "52 kWh/512 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The VW ID4 2024 Pure + Version offers a blend of practicality and advanced technology in an all-electric SUV. It includes heated seats and GPS navigation, ensuring a comfortable and efficient driving experience for all journeys."
  },

  {
    id: 8,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota Camry hybrid 2024 model 2.0HE Elite Version",
    imgUrl: img08,
    model: "152 HP",
    price: "27,500",
    speed: "kWh/100km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota Camry Hybrid 2024 Model 2.0HE Elite Version combines efficiency with elegance. Equipped with heated seats and GPS navigation, it provides a smooth, comfortable, and fuel-efficient driving experience, ideal for both city commuting and longer journeys."
  },

  {
    id: 9,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota Camry hybrid 2024 model 2.0HGVP Luxury version",
    imgUrl: img09,
    model: "152 HP",
    price: "29,040",
    speed: "kWh/100km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota Hybrid 2024 Model 2.0HGVP Luxury offers a premium driving experience, blending fuel efficiency with luxury. With heated seats and GPS navigation, it provides comfort and convenience, making it an excellent choice for both city and long-distance driving."
  },

  {
    id: 10,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota Camry hybrid 2024 model 2.0HG Premium Version",
    imgUrl: img10,
    model: "152 HP",
    price: "30,635",
    speed: "kWh/100km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota Camry Hybrid 2024 Model 2.0HG Premium Version is a sophisticated hybrid sedan that delivers exceptional performance and fuel efficiency. Equipped with a 152 HP engine, it combines smooth handling with eco-friendly technology. The Premium Version offers added luxury features such as GPS navigation and heated seats, ensuring a comfortable and convenient driving experience. Whether for daily commutes or long journeys, this Camry provides a perfect balance of power, comfort, and sustainability.",
  },

  {
    id: 11,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota Camry hybrid 2024 model 2.0HS Sports Version",
    imgUrl: img11,
    model: "152 HP",
    price: "31,460.00",
    speed: "kWh/100km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota Camry Hybrid 2024 Model 2.0HS Sports Version is a stylish and efficient sedan that seamlessly blends hybrid technology with sporty performance. Powered by a 152 HP engine, it offers impressive fuel economy while maintaining a smooth and dynamic drive. The Sports Version enhances the driving experience with sportier styling and features like GPS navigation and heated seats. Designed for those who value both performance and comfort, the Camry Hybrid is a great choice for eco-conscious drivers without sacrificing luxury and power.",
  },

  {
    id: 12,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota Camry hybrid 2024 model 2.0HXS Sports PLUS Version",
    imgUrl: img12,
    model: "152 HP",
    price: "31,790",
    speed: "kWh/100km",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota Camry Hybrid 2024 Model 2.0HXS Sports PLUS Version combines powerful hybrid performance with luxury and efficiency. Equipped with a 152 HP engine, it offers an excellent fuel economy while delivering a smooth and responsive drive. The Sports PLUS version comes with modern amenities like GPS navigation and heated seats, ensuring a comfortable and connected driving experience. This hybrid sedan is designed for those who want an eco-friendly vehicle without compromising on performance and style.",
  },

  {
    id: 13,
    brand: "BMW",
    rating: 3.5,
    carName: "BMW IX3 Leading Version",
    imgUrl: img13,
    model: "210 HP",
    price: "35,900",
    speed: "80 kWh/546 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The BMW IX3 Leading Version is a high-performance electric SUV with a 210 HP motor and a range of up to 546 km. This model offers a perfect blend of luxury, advanced technology, and sustainability. Equipped with GPS navigation and heated seats, the IX3 ensures a comfortable and connected driving experience. Its impressive range and powerful electric motor make it an ideal choice for long trips and daily commuting, offering eco-conscious drivers a dynamic yet practical solution.",
  },

  {
    id: 14,
    brand: "BMW",
    rating: 3.5,
    carName: "BMW IX3 Creative Version",
    imgUrl: img14,
    model: "282 HP",
    price: "39,300",
    speed: "80 kWh/460 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The BMW IX3 Creative Version is an all-electric SUV with 282 HP, offering both performance and practicality. With a range of up to 460 km on a full charge, it is ideal for long trips and everyday driving. This model features advanced technology such as GPS navigation and heated seats, ensuring comfort and convenience. The IX3 combines BMW's luxury and driving dynamics with the benefits of zero-emission electric power, making it a smart choice for eco-conscious drivers who value style and performance.",
  },

  {
    id: 15,
    brand: "BMW",
    rating: 3.5,
    carName: "BMW I4 2023 eDrive35 range 511km Rear Drive",
    imgUrl: img15,
    model: "286 HP",
    price: "44,200",
    speed: "67 kWh/511 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The BMW I4 2023 eDrive35 offers a powerful 286 HP engine and a smooth rear-wheel drive experience. With an impressive range of 511 km on a single charge, this electric sedan combines performance and luxury. It features advanced technology like GPS navigation and heated seats, ensuring a comfortable and convenient ride. Designed for those who demand both style and efficiency, the I4 delivers a dynamic driving experience while maintaining sustainability with zero emissions.",
  },

  {
    id: 16,
    brand: "BYD",
    rating: 3.5,
    carName: "BYD Song Plus Flagship Version 605km",
    imgUrl: img16,
    model: "201 HP",
    price: "24,410",
    speed: "71.7 kWh/605 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The BYD Song Plus Flagship Version is a powerful and versatile electric SUV, offering a robust 201 HP engine and an impressive 605 km range on a full charge. Equipped with advanced features like GPS navigation and heated seats, it ensures a comfortable and convenient driving experience. This model is designed for those who need a reliable and spacious electric vehicle, perfect for long trips and urban driving alike. With a sleek design and strong performance, the Song Plus is an ideal choice for eco-conscious drivers seeking both power and efficiency.",
  },

  {
    id: 17,
    brand: "BYD",
    rating: 3.5,
    carName: "BYD Seagule Vitality Version 300km",
    imgUrl: img17,
    model: "75 HP",
    price: "10,520",
    speed: "30 kWh/300 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The BYD Seagull Vitality Version is a compact electric vehicle designed for efficient city driving. With a 75 HP engine and a range of 300 km on a full charge, it offers a great balance of power and energy efficiency. This model comes equipped with GPS navigation and heated seats, ensuring comfort and convenience for everyday commutes. Ideal for urban environments, the Seagull provides a sustainable and affordable option for those looking for a reliable electric car.",
  },

  {
    id: 18,
    brand: "BYD",
    rating: 3.5,
    carName: "BYD Song I 2024 model 550km Excellent Version",
    imgUrl: img18,
    model: "210 HP",
    price: "17,955",
    speed: "81 kWh/550 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The BYD Song I 2024 model is a sleek electric SUV that combines power and efficiency. With a robust 210 HP engine and a 550 km range on a full charge, it's designed for long-distance travel without compromising on comfort. Featuring GPS navigation and heated seats, this vehicle offers a luxurious and convenient driving experience. Perfect for families and long journeys, the Song I excels in both performance and modern features, making it a great choice for those seeking an eco-friendly, reliable vehicle.",
  },

  {
    id: 19,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota BZ3 517km Elite Pro Version",
    imgUrl: img19,
    model: "184 hp",
    price: "18,400",
    speed: "49.90 kWh/517 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota BZ3 517km Elite Pro Version is a high-performance electric sedan that delivers a powerful 184 HP with an impressive range of 517 km on a full charge. It comes equipped with GPS navigation and heated seats, offering a perfect blend of advanced technology and comfort. Ideal for both daily commutes and longer drives, the BZ3 is designed to meet the needs of modern drivers looking for an eco-friendly, yet performance-oriented vehicle.",
  },

  {
    id: 20,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota BZ3 616km Long Battery Life Pro Version",
    imgUrl: img20,
    model: "180 HP",
    price: "19,100",
    speed: "65.30 kWh/616 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota BZ3 616km Long Battery Life Pro Version is an electric sedan that prioritizes long-range efficiency. With 180 HP and a remarkable range of 616 km on a full charge, it is designed for those seeking sustainable, high-performance driving. Equipped with GPS navigation and heated seats, the BZ3 ensures both comfort and advanced technology for a smooth and modern driving experience, whether for daily commuting or longer trips.",
  },

  {
    id: 21,
    brand: "Toyota",
    rating: 3.5,
    carName: "Toyota BZ4X Long Pro Version",
    imgUrl: img21,
    model: "204 HP",
    price: "23,620",
    speed: "66.70 kWh/367 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Toyota BZ4X Long Pro Version is an electric SUV designed for efficiency and comfort. Powered by a 204 HP motor, it delivers an impressive range of 367 km on a full charge. Featuring GPS navigation and heated seats, the BZ4X offers a modern driving experience with advanced technology and eco-friendly performance, making it a great choice for those seeking sustainability without compromising on comfort.",
  },

  {
    id: 22,
    brand: "Mercedes",
    rating: 3.5,
    carName: "Mercedes EQA 260, 619km",
    imgUrl: img22,
    model: "188 HP",
    price: "30,500",
    speed: "73.5 kWh/619 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Mercedes EQA 260 is an electric compact SUV designed for performance and efficiency. With a 619 km range and a powerful 188 HP engine, it offers a smooth and sustainable driving experience. The vehicle comes equipped with GPS navigation and heated seats, ensuring comfort and convenience for both short trips and long drives. Ideal for those seeking an eco-friendly and stylish vehicle with advanced features.",
  },

  {
    id: 23,
    brand: "Mercedes",
    rating: 4.5,
    carName: "Mercedes EQB260",
    imgUrl: img23,
    model: "190 HP",
    price: "34,400",
    speed: "73.50 kWh/517 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Mercedes EQB260 is a stylish and efficient electric SUV that combines modern technology with comfort. With a range of 517 km on a single charge and a powerful 190 HP engine, it offers an excellent driving experience for both city and long-distance travels. Features such as heated seats and GPS navigation ensure a comfortable and convenient ride, making it perfect for families and adventure enthusiasts alike.",
  },

  {
    id: 24,
    brand: "Mercedes",
    rating: 4.5,
    carName: "Mercedes EQE 350 Pioneer-752km",
    imgUrl: img24,
    model: "288 HP",
    price: "51,500",
    speed: "69.7 kWh/752 KM",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The Mercedes EQE 350 Pioneer is an all-electric sedan that offers a perfect combination of luxury and performance. With a long range of 752 km on a single charge and a powerful 288 HP engine, this vehicle provides both comfort and efficiency. Features like heated seats and GPS navigation enhance the driving experience, making it ideal for both long journeys and daily commutes.",
  },

  {
    id: 25,
    brand: "ZenTrike",
    rating: 4.5,
    carName: "ZenTrike",
    imgUrl: img25,
    model: "20 - 100 KM",
    price: "4,835",
    speed: "50 - 55 Km/h",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The ZenTrike is an electric tricycle that combines eco-friendly mobility with convenience and comfort. With a range of 20 to 100 km on a single charge, it offers versatility for both short commutes and longer trips. Capable of speeds ranging from 50 to 55 km/h, this tricycle provides a smooth, efficient ride. Equipped with features like GPS navigation and heated seats, the ZenTrike ensures a modern and comfortable travel experience while reducing your carbon footprint.",
  },

  {
    id: 26,
    brand: "ElectricRoar",
    rating: 4.5,
    carName: "ElectricRoar",
    imgUrl: img26,
    model: "80 - 100 KM",
    price: "4,947",
    speed: "50 - 55 Km/h",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The ElectricRoar is an eco-friendly electric tricycle designed for modern urban mobility. Offering a range of 80 to 100 km on a single charge, it’s perfect for both daily commuting and longer trips. With a top speed of 50 to 55 km/h, it delivers a smooth, efficient ride. Features such as GPS navigation and heated seats ensure a comfortable and convenient driving experience, making the ElectricRoar an excellent choice for eco-conscious riders looking for a sustainable and practical transport solution.",
  },

  {
    id: 27,
    brand: "TriRover",
    rating: 4.5,
    carName: "TriRover",
    imgUrl: img27,
    model: "80 - 100 KM",
    price: "4,551",
    speed: "300 kg w/o Batt",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The TriRover is a robust, electric tricycle designed for both work and leisure. With a range of 80 to 100 km on a single charge, it's equipped to handle daily commutes or longer trips. The vehicle can carry up to 300 kg without the battery, ensuring it can transport goods or additional passengers with ease. With features like GPS navigation and heated seats, the TriRover offers a comfortable and efficient ride, making it a great choice for those who need reliable, eco-friendly transportation.",
  },

  {
    id: 28,
    brand: "OgreTigre",
    rating: 4.5,
    carName: "OgreTigre",
    imgUrl: img28,
    model: "80 - 100 KM",
    price: "6,238",
    speed: "55 Km/h",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Electric",
    description:
      "The OgreTigre is an electric tricycle designed to combine utility with comfort. It offers a range of 80 to 100 km on a single charge, making it a great option for both daily commutes and longer trips. The tricycle reaches speeds of up to 55 km/h and features GPS navigation for easy route planning. With heated seats for added comfort, the OgreTigre ensures a pleasant riding experience in all weather conditions. It’s perfect for those looking for an eco-friendly, efficient, and practical transportation solution.",
  },
];

export default carData;

